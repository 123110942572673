import React, { Component } from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  BlockImageTextHalf,
  Cubes,
  HalfWidthBlock,
  PaddingWrapper,
  ReferenceBanner,
  ReferenceContainer,
  TitleH2,
} from "@igloonet-web/shared-ui"

import Logo from "../../../images/reference/skinners/logo.png"
import Top from "../../../images/reference/skinners/top.jpg"
import Background from "../../../images/reference/skinners/background.png"
import Background2 from "../../../images/reference/skinners/background2.png"
import Middle from "../../../images/reference/skinners/middle.png"
import About from "../../../images/reference/skinners/about.png"
import Banner from "../../../images/reference/skinners/bottom-banner.png"
import Databaze from "../../../images/reference/skinners/databaze.jpg"
import Template from "../../../images/reference/skinners/template.gif"
import Foot from "../../../images/reference/skinners/wireframe1.png"
import Graf from "../../../images/reference/skinners/graf.svg"
import Arm from "../../../images/reference/skinners/arm.svg"

const GrayContainer = styled.div`
  background: #f8f9fa;
`

class Skinners extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      backgroundImage: `url(${Top})`,
    }

    return (
      <Layout>
        <Helmet>
          <title>Reference na marketingové práce pro Skinners | igloonet</title>
          <meta
            name="description"
            content="Ve spolupráci jsme pravidelně komunikovali se zákazníky, fanoušky a backery, které Skinners nasbíraly na jednotlivých platformách."
          />
        </Helmet>

        <GrayContainer>
          <ReferenceBanner
            styles={pozadi}
            logo={Logo}
            extodkaz="//skinners.cc"
            heading="Nastavení e-mailové komunikace Skinners"
            odstavec="leden 2018 - srpen 2019"
          />

          <ReferenceContainer>
            <BlockImageTextHalf
              className="d-md-none"
              image={Background}
              alt={"ALT"}
              hideImageOnSmall
            >
              <TitleH2>Co nás čekalo</TitleH2>

              <p>
                Nastavení komunikace s backery a odběrateli Skinners hlavně z
                Kickstarteru a IndieGoGo.
              </p>
              <Cubes
                data={[
                  {
                    description: "Open rate přes",
                    number: "50 %",
                  },
                  {
                    description: "Zvýšení CLV",
                    image: Graf,
                  },
                  {
                    description: "Vysoký engagement publika",
                    image: Arm,
                  },
                ]}
              />
            </BlockImageTextHalf>

            <BlockImageTextHalf
              className="d-md-none"
              image={Background2}
              alt={"ALT"}
              right
              hideImageOnSmall
            >
              <TitleH2>Představení</TitleH2>

              <p>
                Brněnské ponožkoboty Skinners odstartovaly úspěšnou Kickstarter
                kampaní v roce 2016. Celkově vybraly 654 557 dolarů od 9 624
                backerů a zařadily se mezi nejúspěšnější české crowdfundingové
                projekty. Po úspěšné kampaní se celý projekt přesunul na
                platformu IndieGoGo, kde dodnes utržil přes 2,2 milionu dolarů.
              </p>
              <p>
                Skinners jsou v tuto chvíli k dostání přes širokou síť partnerů,
                IndieGoGo, Amazon nebo přímo přes e-shop. Zboží doručují do více
                než 100 zemí světa.
              </p>
              <p>
                Samotný produkt způsobil menší revoluci v barefoot obuvi.
                Skinners přináší volnost a skladnost ponožek při obdobné ochraně
                nohy, jakou nabízí pevná obuv, to vše v minimalistickém designu.
                Právě za design už získaly tři ceny: Red Dot Award, A’Design
                Award a Good Design Award.
              </p>
            </BlockImageTextHalf>

            <PaddingWrapper>
              <HalfWidthBlock>
                <TitleH2>Cíl spolupráce</TitleH2>
                <p className="text-center">
                  Pravidelně komunikovat se zákazníky, fanoušky a backery, které
                  Skinners nasbíraly na jednotlivých platformách. E-mail však
                  nemá primárně fungovat jako prodejní kanál.
                </p>

                <PaddingWrapper>
                  <h4>Hlavní cíle spolupráce</h4>
                  <ul>
                    <li>Zvyšování zapojení (engagementu) odběratelů</li>
                    <li>Obsahový servis kolem značky a samotného produktu</li>
                    <li>Práce s databází</li>
                  </ul>
                </PaddingWrapper>
              </HalfWidthBlock>
            </PaddingWrapper>
          </ReferenceContainer>

          <img
            src={Middle}
            alt=""
            className="img-fluid d-none d-md-flex m-auto"
            loading="lazy"
          />

          <ReferenceContainer>
            <PaddingWrapper>
              <BlockImageTextHalf
                className="d-md-none"
                image={About}
                alt={"ALT"}
                right
                hideImageOnSmall
              >
                <TitleH2 id="mailing">
                  Nastavení technického pozadí doručitelnost
                </TitleH2>

                <p>
                  Pro rozesílání e-mailových kampaní jsme vybrali Mailkit. A to
                  hlavně pro jeho vysokou doručitelnost a práci s e-mailovými
                  šablonami.
                </p>
                <p>
                  Společně se Skinners jsme nastavili technické náležitosti pro
                  maximální doručitelnost, konkrétně nezbytné SPF, DKIM a DMARC.
                </p>
                <p>
                  Jelikož Mailkit nebyl jedinou platformou pro rozesílání
                  e-mailů (e-shop, IndieGoGo, poštovní server, CRM), bylo
                  potřeba vše implementovat tak, aby nedocházelo k zahazování či
                  nedoručení některé z transakční nebo běžné e-mailové
                  komunikace.
                </p>
              </BlockImageTextHalf>
            </PaddingWrapper>

            <img src={Banner} alt="" className="img-fluid" loading="lazy" />

            <PaddingWrapper>
              <BlockImageTextHalf
                className="d-md-none"
                image={Databaze}
                alt={"ALT"}
                hideImageOnSmall
              >
                <TitleH2>Práce s databází</TitleH2>

                <p>
                  S databází odběratelů jsme pracovali podle zdroje (platformy),
                  přes níž se uživatel přihlásil k odběru. Již od začátku jsme
                  komunikaci personalizovali a přistupovali ke každé platformě
                  odlišným způsobem. Uživatele jsme dále segmentovali dle
                  lokality, což nám mj. umožnilo cíleně rozesílat pozvánky na
                  zajímavé eventy, kterých se Skinners účastní po celém světě.
                </p>
                <p>
                  Další vrstvou segmentace pro nás byla RFM analýza, přinášející
                  zajímavý pohled na odběratele-zákazníky. Vyhodnocovali jsme
                  jejich celkové hodnoty a počty objednávek a období od
                  posledního nákupu. Tím získal každý uživatel určité skóre,
                  které jej přiřadilo k některému ze segmentů.
                </p>
                <p>
                  Pokud se o RFM analýze chcete dozvědět více, přečtěte si náš
                  článek{" "}
                  <a href="https://igloonet.cz/blog/vitejte-planete-her/">
                    RFM: Analýza zákazníků v kostce.
                  </a>
                </p>
              </BlockImageTextHalf>
            </PaddingWrapper>

            <PaddingWrapper>
              <BlockImageTextHalf
                className="d-md-none"
                image={Template}
                alt={"ALT"}
                right
                hideImageOnSmall
              >
                <TitleH2>Drag&Drop šablona</TitleH2>

                <p>
                  Obrovskou úsporou času při tvorbě obsahu pro nás byla
                  Drag&Drop šablona. Díky ní jsme mohli nad jednotlivými částmi
                  e-mailů přemýšlet jako nad moduly. Ty pak pro každý e-mail
                  přeskládat dle informační struktury a typu zasílaného obsahu.
                </p>
                <p>
                  Každý modul má obecně svůj vlastní kód a je tedy editovatelný
                  zvlášť. To nám při práci dává možnost převést do Drag&Dropu
                  jakýkoliv návrh grafika. Nenarážíme na hranice editoru jako u
                  konkurenčních mailovacích nástrojů.
                </p>
              </BlockImageTextHalf>
            </PaddingWrapper>

            <PaddingWrapper>
              <BlockImageTextHalf
                className="d-md-none"
                image={Foot}
                alt={"ALT"}
                hideImageOnSmall
              >
                <TitleH2>Výsledky</TitleH2>

                <p>
                  Databáze odběratelů reagovala díky segmentaci a
                  personalizovanému přístupu k obsahu velice dobře. Kampaně měly
                  dlouhodobý průměr otevření přes 50 %.
                </p>
                <p>
                  E-mail měl i přes svůj neprodejní obsah velice pozitivní vliv
                  na objednávky. U uživatelů se nám přes něj dařilo zvyšovat
                  celkový počet nákupů na zákazníka, a tím i jeho hodnotu (CLV)
                  pro e-shop.
                </p>
                <p>
                  Engagement u klientů monitorujeme přes metriku Mailkitu,
                  hodnotící uživatele od 1 do 5, kdy 5 je nejlepší. U Skinners
                  jsme v průměru nad databází dosahovaly hodnoty engagementu
                  3,5. Postupně se nám dařilo hodnotu zvyšovat.
                </p>
              </BlockImageTextHalf>
            </PaddingWrapper>

            <PaddingWrapper>
              <HalfWidthBlock>
                <TitleH2>Co dál?</TitleH2>
                <p>
                  Se Skinners jsme pracovali na sérii automatických kampaní
                  sloužících ke komunikaci před nákupem i po něm. Po posílení
                  marketingového týmu klienta jsme e-mailing předali k interní
                  správě. Podobně jako celosvětové kampaně na sociálních sítích
                  a ve vyhledávání, které jsme od počátku nastavili a
                  spravovali.
                </p>
              </HalfWidthBlock>
            </PaddingWrapper>
          </ReferenceContainer>
        </GrayContainer>

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Skinners
